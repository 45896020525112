const locales = require("../../config/i18n");

function _linkResolver(doc) {
  switch (doc.type) {
    case "page":
      return `/${doc.uid}`;
    case "article":
      return `/a/${doc.uid}`;
    case "category":
      return `/c/${doc.uid}`;
    default:
      return "/";
  }
}

module.exports =
  ({ node, key, value }) =>
  (doc) => {
    if (!doc.uid) return "/";

    if (doc.data && doc.data.home_page) {
      return locales[doc.lang].default ? "/" : `/${locales[doc.lang].path}`;
      return "/";
    }
    console.log(doc);
    const link = _linkResolver(doc);
    console.log(link);
    return locales[doc.lang].default
      ? `/${link}`
      : `/${locales[doc.lang].path}${link}`;
  };
