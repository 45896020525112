import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'

// import linkResolver from '../core/linkResolver'
const linkResolver = require("../core/linkResolver")

import { Layout } from '../components/Layout'

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!'
  
  // return (
  //   <div className="preview">
  //     <p>Loading...</p>
  //   </div>
  // )
  return (
    <Layout>
      <div className="preview">
        <p>Loading...</p>
      </div>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRIMIC_REPO_NAME,
  // repositoryName: 'projectstarter',
  linkResolver,
})
